import { AppsManagementResponse, ItemFile } from '../../types/common.type';
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { catchError, filter, from, map, mergeMap, Observable, of } from 'rxjs';
import { FileImageData } from '@shared/data/file.data';
import { environment } from '@environment/environment';

@Injectable({
    providedIn: 'root',
})
export class FileImageService extends FileImageData {
    private HttpClient = inject(HttpClient);

    constructor() {
        super();
    }
    uploadUrl: string = 'https://uploader.volio.vn';

    PostFileImage(file: any): Observable<AppsManagementResponse<ItemFile>> {
        let url = this.uploadUrl + '/uploader/api/v1.0/upload';
        return this.HttpClient.post<AppsManagementResponse<ItemFile>>(
            url,
            file,
            { headers: { 'error-handler': 'no' } }
        );
    }

    uploadFileToAWS(
        linkUpload: string,
        fileBinary: any
    ): Observable<HttpEvent<any>> {
        const url = linkUpload;
        return this.HttpClient.request('PUT', url, {
            body: fileBinary,
            observe: 'events',
            reportProgress: true,
            headers: { 'error-handler': 'no' },
        });
    }

    completeUpload(data: any): Observable<AppsManagementResponse<ItemFile>> {
        const url = this.uploadUrl + '/uploader/api/v1.0/upload';

        return this.HttpClient.put<AppsManagementResponse<ItemFile>>(
            url,
            data,
            { headers: { 'error-handler': 'no' } }
        );
    }

    UploadMultipleFile(loader: any) {
        const arrayItem = [];
        return new Promise((resolve, reject) => {
            return from(loader)
                .pipe(
                    map((file) => file),
                    mergeMap((file: any) => {
                        const ext = file.name.split('.');

                        let dataUpload = {
                            app_key: 'uploader',
                            name: file.name.split(' ').join('_'),
                            mime_type: file.type,
                            file_size: file.size,
                            ext: ext.length > 0 ? ext[ext.length - 1] : null,
                        };

                        return this.PostFileImage(dataUpload).pipe(
                            filter(
                                (resp) =>
                                    resp.message === 'success' &&
                                    resp.data &&
                                    resp.data.upload_links.length > 0
                            ),
                            map((r) => {
                                return { f: file, r };
                            }),
                            catchError((err) => {
                                console.log('addFile ERR: ', err);
                                return of(err);
                            })
                        );
                    }),
                    mergeMap((resp1) => {
                        const parentLinkUpload = resp1.r.data.upload_links;
                        let linkUpload;
                        const file = resp1.f;
                        linkUpload = parentLinkUpload[0].link;
                        return this.uploadFileToAWS(linkUpload, file).pipe(
                            map((r: HttpEvent<any>) => {
                                return {
                                    r: r,
                                    d: resp1.r.data,
                                    p: parentLinkUpload[0].part_idx,
                                };
                            })
                        );
                    }),
                    filter((event: any) => {
                        return (
                            event.r.type === HttpEventType.Response &&
                            event.r.headers.get('Etag') !== ''
                        );
                    }),
                    mergeMap((resp2: any) => {
                        const Etag = resp2.r.headers.get('Etag');
                        const listPartsFile = {
                            etag: Etag,
                            part_idx: resp2.p,
                        };
                        const dataUploaded = {
                            id: resp2.d.id,
                            upload_id: resp2.d.upload_id,
                            uploaded_parts: [listPartsFile],
                        };

                        return this.completeUpload(dataUploaded).pipe(
                            map((r) => {
                                return r;
                            }),
                            catchError((err) => {
                                console.log('ERR: ', err);
                                return of(err);
                            })
                        );
                    })
                )
                .subscribe((res2: any) => {
                    arrayItem.push({
                        link: res2.data.link_cdn,
                        name: res2.data.name,
                        type: res2.data.mime_type,
                    });

                    if (arrayItem.length == loader.length) {
                        return resolve(arrayItem);
                    }
                });
        });
    }

    // uploadFileStore(
    //     file: FormData,
    //     id: number | string
    // ): Observable<AppsManagementResponse<any>> {
    //     const headers = new HttpHeaders({
    //         Authorization: `Bearer ${environment.tokenUser}`,
    //     });
    //     let url = environment.storesUrl + `/api/stores/${id}/files`;
    //     return this.HttpClient.post<AppsManagementResponse<any>>(url, file, {
    //         headers,
    //     });
    // }
}

import { Observable } from 'rxjs';
import { AppsManagementResponse } from '../../types/common.type';
import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface ItemFile {
    id: string;
    app_key: string;
    region: string;
    key: string;
    name: string;
    size?: number;
    ext?: string;
    mime_type?: string;
    upload_id?: string;
    part_split_size?: number;
    upload_links: [
        {
            link: string;
        }
    ];
    creator_id: string;
    link_cdn?: string;
}

export interface FileUpload {
    access_hash: string;
    app_key: string;
    id: string;

    region: string;
    key: string;
    name: string;
    size: number;
    ext?: string;
    mime_type: string;
    upload_id?: string;
    part_split_size?: number;
    link_cdn: string;
    creator_id: string;
}

export interface FileMedia {
    id: string;
    file_size: number;
    file_type: string;
    mime_type: string;
    name: string;
    url: string;
    created_time: number;
    updated_time: number;
}

export interface IModalMediaData {
    itemMedia: FileMedia;
}
@Injectable({
    providedIn: 'root',
})
export abstract class FileImageData {
    // abstract uploadFileStore(
    //     file: FormData,
    //     id: string | number | null
    // ): Observable<AppsManagementResponse<any>>;
    abstract PostFileImage(
        file: any
    ): Observable<AppsManagementResponse<ItemFile>>;
    abstract uploadFileToAWS(
        linkUpload: string,
        fileBinary: any
    ): Observable<HttpEvent<any>>;
    abstract completeUpload(
        data: any
    ): Observable<AppsManagementResponse<ItemFile>>;
}

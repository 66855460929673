import { Observable } from 'rxjs';
import { Localizations, CMSResponse } from '../../types/common.type';

export interface IContact {
    about?: string;
    assets: IAssetContact[];
    career_id?: string;
    email: string;
    locale_id?: string;
    name: string;
    phone: string;
}

export interface IAssetContact {
    link?: string;
    name?: string;
    type?: string;
}

export abstract class ContactData {
    abstract PostInforContact(
        data: IContact
    ): Observable<CMSResponse<IContact>>;
}
